body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background: #000; */
  background-color: #1d1d24;
}
.middle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
h1 {
  font-size: 3rem;
  font-weight: 100;
  color: #f90048;
  animation: colorChange 1.2s linear infinite;
}
.bar {
  width: 10px;
  height: 70px;
  background: #fff;
  display: inline-block;
  transform-origin: bottom center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  animation: loader 1.2s linear infinite;
}
.bar1 {
  animation-delay: 0.1s;
}
.bar2 {
  animation-delay: 0.2s;
}
.bar3 {
  animation-delay: 0.3s;
}
.bar4 {
  animation-delay: 0.4s;
}
.bar5 {
  animation-delay: 0.5s;
}
.bar6 {
  animation-delay: 0.6s;
}
.bar7 {
  animation-delay: 0.7s;
}
.bar8 {
  animation-delay: 0.8s;
}

@keyframes loader {
  0% {
    transform: scaleY(0.1);
  }
  50% {
    transform: scaleY(1);
    background: #f90048;
  }
  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}
@keyframes colorChange {
  0% {
    color: #f0a1b7;
  }
  50% {
    color: #fc3870;
  }
  100% {
    color: #f90048;
  }
  50% {
    color: #fc3870;
  }
  0% {
    color: #f0a1b7;
  }
}
