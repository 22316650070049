.App {
  background-color: #1d1d24;
}
::-webkit-scrollbar {
  width: 10px;
  height: 20px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c9c9c9;
  margin-top: 00px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c00036;
}